import PageHeadingContainer from "../../components/PageHeadingContainer/PageHeadingContainer";
import {Heading1} from "../../components/Heading/Heading";
import React, {useState} from "react";
import {Spinner, SpinnerOverlay, SpinnerWrapper} from "../../components/Spinner/Spinner";
import {Await, defer, Link, useAsyncValue, useLoaderData, useNavigation, useRouteLoaderData} from "react-router-dom";
import {Text} from "../../components/Text/Text";
import styled from "styled-components";
import {AddReservationInvite} from "./Partials/AddReservationInvite";
import Pagination from "../../components/Pagination";
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from "../../components/Table/Table";
import {Checkbox} from "../../components/Input/Input";
import {fetchProtectedData, postData} from "../../api/fetch";
import dayjs from "dayjs";
import {FilterButton} from "./Partials/FilterButton";
import {AppliedFilterList} from "../../components/AppliedFilters/AppliedFilterList";
import qs from "qs";
import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';

const PatientLink = styled(Link)`
    color: var(--color-blue-50);
    font-weight: var(--fw-bold);
    text-decoration: underline;
    cursor: pointer;
`;

const PageHeadingInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    gap: 20px;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

export async function waitingListAction({ request }) {
    let formData = await request.json()
    let intent = formData.intent
    switch (intent) {
        case "add-reservation-invite": {
            delete formData.intent
            return await postData(request, `reservation/invite`, JSON.stringify(formData))
        }
        default:
            return {"default": true}
    }
}

export async function waitingListLoader({ request }) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_RESERVATIONS, Permissions.PATIENT_VIEW]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)

    const page = searchParams.get("page") ?? 1;
    const registeredFrom = searchParams.get("registeredFrom") ?? null;
    const registeredUntil = searchParams.get("registeredUntil") ?? null;
    const healthInsurance = searchParams.getAll("healthInsurance") ?? null;
    const location = searchParams.getAll("location") ?? null;

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(registeredFrom && {registeredFrom}),
        ...(registeredUntil && {registeredUntil}),
        ...(healthInsurance && {healthInsurance}),
        ...(location && {location}),
    }, { arrayFormat: 'comma' });

    const waitingListPromise = fetchProtectedData(request, `reservation/patients${queryString ? `?${queryString}` : ""}`)
    const healthInsuranceData = await fetchProtectedData(request, 'health-insurance');

    return defer({waitingListPromise, healthInsuranceData})
}

const WaitingList = () => {
    const {locationData} = useRouteLoaderData("portal");
    const {waitingListPromise, healthInsuranceData} = useLoaderData();
    const [selected, setSelected] = useState([]); // Lifted state to store checkboxes

    return (
        <>
            <PageHeadingContainer $flexDirection="column" $alignItems='start'>
                <PageHeadingInnerContainer>
                    <Heading1>Uitnodigen reservering Diagnostiekdag</Heading1>
                    <Buttons>
                        <AddReservationInvite
                            selected={selected}
                            onAfterSubmit={() => setSelected([])}
                        />
                        <FilterButton
                            healthInsuranceData={healthInsuranceData}
                            locationData={locationData}
                            onAfterSubmit={() => setSelected([])}
                        />
                    </Buttons>
                </PageHeadingInnerContainer>
                <AppliedFilterList healthInsuranceData={healthInsuranceData} locationData={locationData} />
            </PageHeadingContainer>

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={waitingListPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                    <DeferredWaitingList selected={selected} setSelected={setSelected} />
                </Await>
            </React.Suspense>
        </>
    )
}

function DeferredWaitingList({ selected, setSelected }) {
    const waitingListData = useAsyncValue()
    const navigation = useNavigation()
    const isPending = navigation?.location?.pathname?.includes("/reservation/waiting-list");

    // Handle the checkboxes and select all
    const handleSelectAllOnPage = ({ target: { checked }}) => {
        let _selected = Object.assign([], selected);

        if (checked) {
            waitingListData?.items?.forEach((item) => {
                if (!_selected.includes(item.id)) {
                    _selected.push(item.id)
                }
            })
        } else {
            const ids = waitingListData?.items?.map(i => i.id)
            waitingListData?.items?.forEach((item) => {
                _selected = _selected.filter(s => !ids.includes(s))
            })
        }

        setSelected(_selected)
    }

    const selectedAll = () => {
        return waitingListData?.items?.map(i => i.id).every(s => selected.includes(s))
    }

    const toggleSelected = (id) => {
        if (!selected.includes(id)) {
            setSelected([...selected, id])
        } else {
            setSelected(selected.filter(s => s !== id))
        }
    }

    return (
        <>
            <RelativeWrapper>
                {waitingListData?.items?.length > 0 ? (
                    <>
                        <Pagination searchResults={waitingListData} $position="top"/>
                        <Table>
                            <TableHead>
                                <TableHeadRow>
                                    <TableHeadCell $flex="0">
                                        <Checkbox type='checkbox' checked={selectedAll()} onChange={handleSelectAllOnPage} />
                                    </TableHeadCell>
                                    <TableHeadCell>Naam patient</TableHeadCell>
                                    <TableHeadCell>Locatie</TableHeadCell>
                                    <TableHeadCell>Zorgverzekeraar</TableHeadCell>
                                    <TableHeadCell>Aanmeldingsdatum</TableHeadCell>
                                </TableHeadRow>
                            </TableHead>
                            {waitingListData?.items?.map((item, rowIndex) => {
                                return (
                                    <TableRow key={`row-${rowIndex}`}>
                                        <TableRowInner $isOddRow={rowIndex % 2 === 0}>
                                            <TableRowCell $flex="0">
                                                <Checkbox type='checkbox' checked={selected.includes(item.id)} onChange={() => toggleSelected(item.id)} />
                                            </TableRowCell>
                                            <TableRowCell>
                                                <PatientLink to={{pathname: `/patient/${item.id}`}}>
                                                    { item.name }
                                                </PatientLink>
                                            </TableRowCell>
                                            <TableRowCell>
                                                { item.location?.[0] }
                                            </TableRowCell>
                                            <TableRowCell>
                                                { item.healthInsurance }
                                            </TableRowCell>
                                            <TableRowCell>
                                                { item.registerDate != null && dayjs(item.registerDate).format("DD-MM-YYYY") }
                                            </TableRowCell>
                                        </TableRowInner>
                                    </TableRow>
                                );
                            })}
                        </Table>
                        <Pagination searchResults={waitingListData} />
                    </>
                ) : (
                    <Text>Geen resultaten gevonden</Text>
                )}

                {isPending &&
                    <SpinnerOverlay>
                        <SpinnerWrapper>
                            <Spinner />
                        </SpinnerWrapper>
                    </SpinnerOverlay>
                }
            </RelativeWrapper>
        </>
    )
}

export default WaitingList