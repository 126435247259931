import React from 'react';
import {Heading2} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {NavLink, Outlet, useLoaderData, useParams, useRouteLoaderData} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {deleteData, fetchProtectedData, postData} from '../../api/fetch';
import {ReactComponent as Medicore} from '../../assets/medicore-icon.svg';
import {AddAppAccount} from './Partials/AddAppAccount';
import {DeleteAppAccount} from './Partials/DeleteAppAccount';
import {checkRequiredAccount} from '../Root/Root';
import {checkAccountPermissions} from '../../utils/helpers';
import {Permissions} from '../../constants/enums';
import {CustomLink} from '../../components/CustomLink/CustomLink';
import {CopyButton} from '../../components/CopyButton/CopyButton';
import {SyncButton} from './Partials/SyncButton';
import {useOpenModal} from '../../hooks/useOpenModal';
import {ReactComponent as Trash} from '../../assets/icons/trash.svg';
import {ReactComponent as Edit} from '../../assets/icons/edit.svg';

const InfoListWrapper = styled.div`
    margin: 20px 0 0;
`;

const InfoList = styled.div`
    width: 100%;
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;
    row-gap: 8px;
    
    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`;

const InfoListItem = styled(Text).attrs({
    $noMargin: true,
})``;

const Tabs = styled.div`
    display: flex;
    gap: 4px;
    margin: 50px 0 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--color-divider);
`;

const Tab = styled.button`
    background-color: ${({$isActive}) => $isActive ? 'var(--color-tab)' : 'transparent'};
    color: var(--color-text);
    border-radius: 30px;
    cursor: pointer;
    padding: 8px 20px;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    text-align: center;
    border: none;

    &:hover {
        background-color: var(--color-tab);
    }
`;

const FlexHeading = styled.header`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
`;

const PatientActions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        display: block;
    }
`;

const iconStyles = css`
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 8px;
`;

const TrashIcon = styled(Trash)`
    ${iconStyles};
`;

const EditIcon = styled(Edit)`
    ${iconStyles};
`;

export async function patientAction({request, params}) {
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        case "add-app-account": {
            const body = {
                "phoneNumber": formData.get("phoneNumber"),
                "email": formData.get("email")
            }

            return await postData(request, `patient/${params.patientUUID}/account`, JSON.stringify(body))
        }

        case "delete-app-account": {
            return await deleteData(request, `patient/${params.patientUUID}/account`);
        }

        default:
            return {"default": true};
    }
}

export async function patientLoader({request, params}) {
    await checkRequiredAccount(Permissions.PATIENT_READ);

    const patientData = await fetchProtectedData(request,`patient/${params.patientUUID}`);

    return {patientData};
}

const Patient = () => {
    const {account} = useRouteLoaderData("root");
    const {patientData} = useLoaderData();
    const {patientUUID} = useParams();
    const {isOpen: isOpenDelete, handleOpen: handleOpenDelete, handleClose: handleCloseDelete} = useOpenModal();
    const {isOpen: isOpenAdd, handleOpen: handleOpenAdd, handleClose: handleCloseAdd} = useOpenModal();

    const tabs = [
        {...(checkAccountPermissions(account, Permissions.PATIENTQUESTIONNAIRE_VIEW) && {
            title: "Vragenlijsten",
            slug: `/patient/${patientUUID}`,
            end: true,
        })},
        {...(checkAccountPermissions(account, Permissions.DOCUMENT_VIEW) && {
            title: "Documenten",
            slug: "documenten",
            end: true,
        })},
        {...(checkAccountPermissions(account, Permissions.NOTIFICATION_VIEW) && {
            title: "Berichten",
            slug: "berichten",
            end: false,
        })},
        {...(checkAccountPermissions(account, Permissions.APPOINTMENT_VIEW) && {
            title: "Diagnostiekdag",
            slug: "afspraken",
            end: true,
        })}
    ];

    return (
        <>
            <FlexHeading>
                <Heading2 $noMargin>Persoonsgegevens patiënt</Heading2>
                <PatientActions>
                    <SyncButton patientUUID={patientUUID} />
                    <CopyButton />
                    {patientData?.medicoreUrl && (
                        <CustomLink to={patientData.medicoreUrl} type="external">
                            <Medicore />
                        </CustomLink>
                    )}
                </PatientActions>

            </FlexHeading>

            <InfoListWrapper>
                <InfoList>
                    <InfoListItem><strong>Naam:</strong> {patientData?.name && patientData.name}</InfoListItem>
                    <InfoListItem><strong>E-mailadres:</strong> {patientData?.email && patientData.email}</InfoListItem>
                    <InfoListItem><strong>Geboortedatum:</strong> {patientData?.birthDate && patientData.birthDate}</InfoListItem>
                    <InfoListItem><strong>Telefoonnummer mobiel:</strong> {patientData?.phoneNumberMobile && patientData.phoneNumberMobile}</InfoListItem>
                    <InfoListItem><strong>Gender:</strong> {patientData?.gender && patientData.gender}</InfoListItem>
                    <InfoListItem><strong>Telefoonnummer thuis:</strong> {patientData?.phoneNumberHome && patientData.phoneNumberHome}</InfoListItem>
                    <InfoListItem><strong>Adres:</strong> {patientData?.address && patientData.address}</InfoListItem>
                    <InfoListItem><strong>Telefoonnummer werk:</strong> {patientData?.phoneNumberWork && patientData.phoneNumberWork}</InfoListItem>
                    <InfoListItem><strong>BSN:</strong> {patientData?.bsn && patientData.bsn}</InfoListItem>
                    <InfoListItem><strong>Locatie:</strong> {patientData?.location?.[0] && patientData.location[0]}</InfoListItem>
                    <InfoListItem><strong>App account:</strong> {patientData?.account ? <>Ja<TrashIcon onClick={handleOpenDelete} /></> : <>Nee<EditIcon onClick={handleOpenAdd} /></>}</InfoListItem>
                    <InfoListItem><strong>Verzekering:</strong> {patientData?.healthInsurance && patientData.healthInsurance}</InfoListItem>
                    <InfoListItem><strong>Laatste app sessie:</strong> {patientData?.lastAppSession && patientData.lastAppSession}</InfoListItem>
                    <InfoListItem><strong>Medicore ID:</strong> {patientData?.medicoreID && patientData.medicoreID}</InfoListItem>
                </InfoList>
            </InfoListWrapper>

            <Tabs>
                {tabs.map(item => (
                    <NavLink key={item.slug} to={item.slug} end={item.end}>
                        {({isActive}) => (
                            <Tab $isActive={isActive}>
                                {item.title}
                            </Tab>
                        )}
                    </NavLink>
                ))}
            </Tabs>

            <Outlet />

            <DeleteAppAccount isOpen={isOpenDelete} handleClose={handleCloseDelete} />
            <AddAppAccount isOpen={isOpenAdd} handleClose={handleCloseAdd} />
        </>
    );
}

export default Patient;