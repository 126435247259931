import styled from "styled-components";
import {FormFieldWrapper} from "../../../components/FormFields/FormFields";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {PortalDateTimeField} from "../../../components/PortalFormFields/PortalDateTimeField/PortalDateTimeField";
import dayjs from "dayjs";
import {PortalSelectField} from "../../../components/PortalFormFields/PortalSelectField/PortalSelectField";
import {FilterButtonBase} from '../../../components/FilterButtonBase/FilterButtonBase';

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    min-width: 0;
`;

export function FilterButton({healthInsuranceData, locationData, onAfterSubmit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // Set the unappliedFilters initially to current searchParams (retreived from searchParams
    const [unappliedFilters, setUnappliedFilters] = useState({
        registeredFrom: searchParams.get("registeredFrom"),
        registeredUntil: searchParams.get("registeredUntil"),
        healthInsurance: searchParams.getAll("healthInsurance"),
        location: searchParams.getAll("location"),
    });

    // Keep unappliedFilters correct when searchParams change (when deleting in AppliedFilterList)
    useEffect(() => {
        setUnappliedFilters({
            registeredFrom: searchParams.get("registeredFrom"),
            registeredUntil: searchParams.get("registeredUntil"),
            healthInsurance: searchParams.getAll("healthInsurance"),
            location: searchParams.getAll("location"),
        })
    }, [searchParams]);

    function handleUnappliedFiltersChange(key, value) {
        setUnappliedFilters({
            ...unappliedFilters,
            [key]: value
        })
    }

    function handleApply() {
        // Set search params optionally
        const newParams = {
            ...(unappliedFilters.registeredFrom ? {registeredFrom: unappliedFilters.registeredFrom} : {}),
            ...(unappliedFilters.registeredUntil ? {registeredUntil: unappliedFilters.registeredUntil} : {}),
            ...(unappliedFilters.healthInsurance ? {healthInsurance: unappliedFilters.healthInsurance} : {}),
            ...(unappliedFilters.location ? {location: unappliedFilters.location} : {}),
        }
        setSearchParams(newParams, {replace: true, preventScrollReset: true});
        onAfterSubmit();
        setIsOpen(false);
    }

    // Select fields options
    const healthInsuranceFilterOptions = healthInsuranceData?.map(item => ({value: item.id, label: item.name})) ?? [];
    const locationFilterOptions = locationData?.map(item => ({value: item.id, label: item.name})) ?? [];

    return (
        <FilterButtonBase isOpen={isOpen} setIsOpen={setIsOpen} handleApply={handleApply}>
            <StyledFormFieldWrapper
                id="registeredFrom"
                label="Aangemeld vanaf"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    minDate={null}
                    hideTime={true}
                    defaultValue={unappliedFilters?.registeredFrom ? dayjs(unappliedFilters.registeredFrom).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("registeredFrom", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="registeredUntil"
                label="Aangemeld vóór"
            >
                <PortalDateTimeField
                    placeholder="Kies een datum"
                    minDate={null}
                    hideTime={true}
                    defaultValue={unappliedFilters?.registeredUntil ? dayjs(unappliedFilters.registeredUntil).format("DD-MM-YYYY") : ""}
                    onChange={(date) => handleUnappliedFiltersChange("registeredUntil", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="healthInsurance"
                label="Zorgverzekeraar"
            >
                <PortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={healthInsuranceFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("healthInsurance", option?.filter(o => o?.value?.length > 0).map(o => o?.value?.toLowerCase()))}
                    defaultValue={healthInsuranceFilterOptions?.filter(o => unappliedFilters?.healthInsurance?.includes(o?.value?.toLowerCase()))}
                />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper
                id="location"
                label="Locatie"
            >
                <PortalSelectField
                    isMulti={true}
                    placeholder="Alle"
                    options={locationFilterOptions}
                    onChange={(option) => handleUnappliedFiltersChange("location", option?.filter(o => o?.value?.length > 0).map(o => o.value))}
                    defaultValue={locationFilterOptions?.filter(o => unappliedFilters?.location?.includes(o.value))}
                />
            </StyledFormFieldWrapper>
        </FilterButtonBase>
    );
}