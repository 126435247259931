export const Statuses = {
    LOADING: "loading",
    REVALIDATING: "revalidating",
    IDLE: "idle",
    SUBMITTING: "submitting",
    SUCCESS: "success"
};

// NOTE: The commented permissions are unused in the portal (yet)
export const Permissions = {
    PORTAL_ACCESS_SEARCH: "PORTAL_ACCESS_SEARCH",
    PORTAL_ACCESS_RESERVATIONS: "PORTAL_ACCESS_RESERVATIONS",
    PORTAL_ACCESS_QUESTIONNAIRE_OVERVIEW: "PORTAL_ACCESS_QUESTIONNAIRE_OVERVIEW",
    PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW: "PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW",

    APPOINTMENT_VIEW: "APPOINTMENT_VIEW",
    // APPOINTMENT_READ: "APPOINTMENT_READ",
    // APPOINTMENT_EDIT: "APPOINTMENT_EDIT",
    APPOINTMENT_DELETE: "APPOINTMENT_DELETE",
    APPOINTMENT_CREATE: "APPOINTMENT_CREATE",

    DOCUMENT_VIEW: "DOCUMENT_VIEW",
    DOCUMENT_READ: "DOCUMENT_READ",
    // DOCUMENT_EDIT: "DOCUMENT_EDIT",
    DOCUMENT_DELETE: "DOCUMENT_DELETE",
    DOCUMENT_CREATE: "DOCUMENT_CREATE",

    NOTIFICATION_VIEW: "NOTIFICATION_VIEW",
    NOTIFICATION_READ: "NOTIFICATION_READ",
    NOTIFICATION_EDIT: "NOTIFICATION_EDIT",
    NOTIFICATION_DELETE: "NOTIFICATION_DELETE",
    NOTIFICATION_CREATE: "NOTIFICATION_CREATE",

    PATIENT_VIEW: "PATIENT_VIEW",
    PATIENT_READ: "PATIENT_READ",
    // PATIENT_EDIT: "PATIENT_EDIT",
    // PATIENT_DELETE: "PATIENT_DELETE",
    // PATIENT_CREATE: "PATIENT_CREATE",

    PATIENTQUESTIONNAIRE_VIEW: "PATIENTQUESTIONNAIRE_VIEW",
    PATIENTQUESTIONNAIRE_READ: "PATIENTQUESTIONNAIRE_READ",
    PATIENTQUESTIONNAIRE_EDIT: "PATIENTQUESTIONNAIRE_EDIT",
    PATIENTQUESTIONNAIRE_DELETE: "PATIENTQUESTIONNAIRE_DELETE",
    PATIENTQUESTIONNAIRE_CREATE: "PATIENTQUESTIONNAIRE_CREATE"
}

export const Environments = {
    DEV: "dev",
    TEST: "test",
    ACCEPT: "acceptance",
    PRODUCTION: "production",
    DEFAULT: "default",
}

export const LogoutReasons = {
    TOKEN_EXPIRED: "token_expired",
    MANUALLY: "manually",
}