import {Button, buttonVariants} from "../../../components/Button/Button";
import {useOpenModal} from "../../../hooks/useOpenModal";
import ConfirmationModal, {CancelButton} from "../../../components/Modal/ConfirmationModal";
import {Text} from "../../../components/Text/Text";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Statuses} from "../../../constants/enums";
import {useFetcher} from "react-router-dom";

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

export const AddReservationInvite = ({ selected, onAfterSubmit }) => {
    const {isOpen, handleOpen, handleClose} = useOpenModal()

    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);
    const disabled = status === Statuses.SUBMITTING;

    const onSubmit = () => {
        setStatus(Statuses.SUBMITTING)
        setError(false)

        fetcher.submit({
            intent: "add-reservation-invite",
            sendTo: selected
        }, { method: "POST", encType: "application/json", action: "/reservation/waiting-list"})
    }

    useEffect(() => {
        if(fetcher?.state === "idle") {
            if(fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if(fetcher?.data || fetcher?.data === "") {
                setStatus(Statuses.SUCCESS);
                handleClose();
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const handleOnExited = () => {
        onAfterSubmit();
        setStatus(Statuses.IDLE);
    }

    return <>
        <Button type='button' disabled={!selected?.length} onClick={handleOpen}>
            Uitnodiging versturen ({ selected?.length ?? 0 })
        </Button>

        <ConfirmationModal isOpen={isOpen} handleOpen={handleOpen} handleClose={handleClose} onExited={status === Statuses.SUCCESS ? handleOnExited : undefined}>
            <ConfirmationModal.Header>Uitnodiging versturen</ConfirmationModal.Header>
            <Text $align="center">
                Weet je zeker dat je de uitnodiging naar <strong>{ selected?.length ?? 0 } patiënten</strong> wil versturen? Je kunt dit niet meer ongedaan maken.
            </Text>
            <StyledButton type="button" disabled={disabled} loading={disabled} onClick={onSubmit}>Ja, versturen</StyledButton>
            <CancelButton onClick={handleClose}>Annuleer</CancelButton>
            {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
        </ConfirmationModal>
    </>
}